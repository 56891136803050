<template>
  <Layout tituloPagina="Solicitudes de instalación de servicios | Precio y meses incluidos">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Configuración de precio y meses incluidos
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      Actualizar
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      Actualizar
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label class="col-md-7">Precio de instalación</label>
              <div class="col-md-5">
                <div class="input-group">
                  <span class="input-group-text">
                    {{ monedaSistema.simbolo }}
                  </span>
                  <input
                    type="number"
                    step="0.01"
                    v-model="ajustes.precio_instalacion"
                    class="form-control text-right"
                    placeholder="0.00"
                  />
                  <span class="input-group-text">
                    {{ monedaSistema.codigo }}
                  </span>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <label class="col-md-7">Meses incluidos en instalación</label>
              <div class="col-md-5">
                <input
                  ref="meses"
                  type="number"
                  v-model="ajustes.meses_incluidos_en_instalacion"
                  class="form-control text-right"
                  placeholder="1"
                  min="0" max="60"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="fa"
                :class="!bandera_spinner ? 'fa-save' : 'fa-spinner fa-spin'"
              ></i>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import SistemaSrv from '@/services/SistemaSrv.js'
export default {
  name: 'PrecioYMesesIncluidos',
  components: { Layout },
  data() {
    return {
      ajustes: {
        precio_instalacion: 0,
        meses_incluidos_en_instalacion: 0
      },
      bandera_spinner: false
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    this.cargarPrecioYMeses()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    actualizar: function() {
      var self = this
      self.bandera_spinner = true

      if(self.ajustes.meses_incluidos_en_instalacion > 60 || self.ajustes.meses_incluidos_en_instalacion == null || self.ajustes.meses_incluidos_en_instalacion == '') {
        iu.msg.warning('Los meses incluidos no pueden superar los 60 meses o 5 años, verifique el valor correctamente.')
        self.$refs.meses.focus()
        self.bandera_spinner = false
        return
      }

      SistemaSrv.actualizar(this.ajustes).then(response=>{
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cargarPrecioYMeses: function() {
      var self = this

      SistemaSrv.precioInstalacion().then(response=>{
        self.ajustes.precio_instalacion = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
      
      SistemaSrv.mesesIncluidosEnInstalacion().then(response=>{
        self.ajustes.meses_incluidos_en_instalacion = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>

</style>